import { e } from '../common/index.module-fa5290f4.js';
import { l as l$1, n as n$1 } from '../common/Swup.modern-15022fc6.js';

function r() {
  return r = Object.assign ? Object.assign.bind() : function (e) {
    for (var t = 1; t < arguments.length; t++) {
      var s = arguments[t];
      for (var r in s) Object.prototype.hasOwnProperty.call(s, r) && (e[r] = s[r]);
    }
    return e;
  }, r.apply(this, arguments);
}
function o() {
  return window.matchMedia("(hover: hover)").matches;
}
function i(e) {
  return !!e && (e instanceof HTMLAnchorElement || e instanceof SVGAElement);
}
const n = window.requestIdleCallback || (e => setTimeout(e, 1)),
  a = ["preloadVisibleLinks"];
class l extends e {
  constructor(e = {}) {
    var s;
    super(), s = this, this.name = "SwupPreloadPlugin", this.requires = {
      swup: ">=4.5"
    }, this.defaults = {
      throttle: 5,
      preloadInitialPage: !0,
      preloadHoveredLinks: !0,
      preloadVisibleLinks: {
        enabled: !1,
        threshold: .2,
        delay: 500,
        containers: ["body"],
        ignore: () => !1
      }
    }, this.options = void 0, this.queue = void 0, this.preloadObserver = void 0, this.preloadPromises = new Map(), this.mouseEnterDelegate = void 0, this.touchStartDelegate = void 0, this.focusDelegate = void 0, this.onPageLoad = (e, t, s) => {
      const {
        url: r
      } = e.to;
      return r && this.preloadPromises.has(r) ? this.preloadPromises.get(r) : s(e, t);
    }, this.onMouseEnter = async function (e) {
      if (e.target !== e.delegateTarget) return;
      if (!o()) return;
      const r = e.delegateTarget;
      if (!i(r)) return;
      const {
          url: n,
          hash: a
        } = l$1.fromElement(r),
        l = s.swup.createVisit({
          to: n,
          hash: a,
          el: r,
          event: e
        });
      s.swup.hooks.callSync("link:hover", l, {
        el: r,
        event: e
      }), s.preload(r, {
        priority: !0
      });
    }, this.onTouchStart = e => {
      if (o()) return;
      const t = e.delegateTarget;
      i(t) && this.preload(t, {
        priority: !0
      });
    }, this.onFocus = e => {
      const t = e.delegateTarget;
      i(t) && this.preload(t, {
        priority: !0
      });
    };
    const {
        preloadVisibleLinks: n
      } = e,
      l = function (e, t) {
        if (null == e) return {};
        var s,
          r,
          o = {},
          i = Object.keys(e);
        for (r = 0; r < i.length; r++) t.indexOf(s = i[r]) >= 0 || (o[s] = e[s]);
        return o;
      }(e, a);
    this.options = r({}, this.defaults, l), "object" == typeof n ? this.options.preloadVisibleLinks = r({}, this.options.preloadVisibleLinks, {
      enabled: !0
    }, n) : this.options.preloadVisibleLinks.enabled = Boolean(n), this.preload = this.preload.bind(this), this.queue = function (e = 1) {
      const t = [],
        s = [];
      let r = 0,
        o = 0;
      function i() {
        o < e && r > 0 && ((s.shift() || t.shift() || (() => {}))(), r--, o++);
      }
      return {
        add: function (e, o = !1) {
          if (e.__queued) {
            if (!o) return;
            {
              const s = t.indexOf(e);
              if (s >= 0) {
                const e = t.splice(s, 1);
                r -= e.length;
              }
            }
          }
          e.__queued = !0, (o ? s : t).push(e), r++, r <= 1 && i();
        },
        next: function () {
          o--, i();
        }
      };
    }(this.options.throttle);
  }
  mount() {
    const e = this.swup;
    e.options.cache ? (e.hooks.create("page:preload"), e.hooks.create("link:hover"), e.preload = this.preload, e.preloadLinks = this.preloadLinks, this.replace("page:load", this.onPageLoad), this.preloadLinks(), this.on("page:view", () => this.preloadLinks()), this.options.preloadVisibleLinks.enabled && (this.preloadVisibleLinks(), this.on("page:view", () => this.preloadVisibleLinks())), this.options.preloadHoveredLinks && this.preloadLinksOnAttention(), this.options.preloadInitialPage && this.preload(n$1())) : console.warn("SwupPreloadPlugin: swup cache needs to be enabled for preloading");
  }
  unmount() {
    var e, t, s;
    this.swup.preload = void 0, this.swup.preloadLinks = void 0, this.preloadPromises.clear(), null == (e = this.mouseEnterDelegate) || e.destroy(), null == (t = this.touchStartDelegate) || t.destroy(), null == (s = this.focusDelegate) || s.destroy(), this.stopPreloadingVisibleLinks();
  }
  async preload(e, s = {}) {
    var r;
    let o, n;
    const a = null != (r = s.priority) && r;
    if (Array.isArray(e)) return Promise.all(e.map(e => this.preload(e)));
    if (i(e)) n = e, ({
      href: o
    } = l$1.fromElement(e));else {
      if ("string" != typeof e) return;
      o = e;
    }
    if (!o) return;
    if (this.preloadPromises.has(o)) return this.preloadPromises.get(o);
    if (!this.shouldPreload(o, {
      el: n
    })) return;
    const l = new Promise(e => {
      this.queue.add(() => {
        this.performPreload(o).catch(() => {}).then(t => e(t)).finally(() => {
          this.queue.next(), this.preloadPromises.delete(o);
        });
      }, a);
    });
    return this.preloadPromises.set(o, l), l;
  }
  preloadLinks() {
    n(() => {
      Array.from(document.querySelectorAll("a[data-swup-preload], [data-swup-preload-all] a")).forEach(e => this.preload(e));
    });
  }
  preloadLinksOnAttention() {
    const {
        swup: e
      } = this,
      {
        linkSelector: t
      } = e.options,
      s = {
        passive: !0,
        capture: !0
      };
    this.mouseEnterDelegate = e.delegateEvent(t, "mouseenter", this.onMouseEnter, s), this.touchStartDelegate = e.delegateEvent(t, "touchstart", this.onTouchStart, s), this.focusDelegate = e.delegateEvent(t, "focus", this.onFocus, s);
  }
  preloadVisibleLinks() {
    if (this.preloadObserver) return void this.preloadObserver.update();
    const {
      threshold: e,
      delay: s,
      containers: r
    } = this.options.preloadVisibleLinks;
    this.preloadObserver = function ({
      threshold: e,
      delay: s,
      containers: r,
      callback: o,
      filter: i
    }) {
      const a = new Map(),
        l = new IntersectionObserver(e => {
          e.forEach(e => {
            e.isIntersecting ? h(e.target) : d(e.target);
          });
        }, {
          threshold: e
        }),
        h = e => {
          var r;
          const {
              href: i
            } = l$1.fromElement(e),
            n = null != (r = a.get(i)) ? r : new Set();
          a.set(i, n), n.add(e), setTimeout(() => {
            const t = a.get(i);
            null != t && t.size && (o(e), l.unobserve(e), t.delete(e));
          }, s);
        },
        d = e => {
          var s;
          const {
            href: r
          } = l$1.fromElement(e);
          null == (s = a.get(r)) || s.delete(e);
        },
        u = () => {
          n(() => {
            const e = r.map(e => `${e} a[*|href]`).join(", ");
            Array.from(document.querySelectorAll(e)).filter(e => i(e)).forEach(e => l.observe(e));
          });
        };
      return {
        start: () => u(),
        stop: () => l.disconnect(),
        update: () => (a.clear(), u())
      };
    }({
      threshold: e,
      delay: s,
      containers: r,
      callback: e => this.preload(e),
      filter: e => {
        if (this.options.preloadVisibleLinks.ignore(e)) return !1;
        if (!e.matches(this.swup.options.linkSelector)) return !1;
        const {
          href: s
        } = l$1.fromElement(e);
        return this.shouldPreload(s, {
          el: e
        });
      }
    }), this.preloadObserver.start();
  }
  stopPreloadingVisibleLinks() {
    this.preloadObserver && this.preloadObserver.stop();
  }
  shouldPreload(e, {
    el: r
  } = {}) {
    const {
      url: o,
      href: i
    } = l$1.fromUrl(e);
    return !(!function () {
      if (navigator.connection) {
        var e;
        if (navigator.connection.saveData) return !1;
        if (null != (e = navigator.connection.effectiveType) && e.endsWith("2g")) return !1;
      }
      return !0;
    }() || this.swup.cache.has(o) || this.preloadPromises.has(o) || this.swup.shouldIgnoreVisit(i, {
      el: r
    }) || r && this.swup.resolveUrl(o) === this.swup.resolveUrl(n$1()));
  }
  async performPreload(e) {
    var s = this;
    const {
        url: r
      } = l$1.fromUrl(e),
      o = this.swup.createVisit({
        to: r
      }),
      i = await this.swup.hooks.call("page:preload", o, {
        url: r
      }, async function (t, r) {
        return r.page = await s.swup.fetchPage(e, {
          visit: t
        }), r.page;
      });
    return i;
  }
}

export default l;
