var darkMode = {
  isDark: function isDark() {
    var value = localStorage.getItem("darkmode");
    if (value) {
      return true;
    } else {
      return false;
    }
  },
  enable: function enable() {
    localStorage.setItem("darkmode", true);
    $("html").addClass("darkmode");
  },
  disable: function disable() {
    localStorage.removeItem("darkmode");
    $("html").removeClass("darkmode");
  }
};
export default darkMode;