import getDecodedToken from "../../../../controller/auth/decodeToken.js";
export var getUser = function getUser() {
  var user = getDecodedToken();
  return user;
};
export var setUser = function setUser(obj) {
  // return localStorage.setItem('userInfo', JSON.stringify(obj));
};
export var removeUser = function removeUser() {
  return localStorage.removeItem("token");
};