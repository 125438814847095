import getNamespace from "../helpers/getNamspace.js";
import Permission from "./Permissions/Permission.js";
import getDecodedToken from "./decodeToken.js";
export var hasPageAccess = function hasPageAccess() {
  var user = getDecodedToken();
  var page = getNamespace();
  if (!user || !page) return false;
  if (page.namespace && page.ref) {
    var _Permission$page$name;
    if (page.namespace == "Profile") {
      return true;
    }
    var userPermissions = (_Permission$page$name = Permission[page.namespace]) === null || _Permission$page$name === void 0 ? void 0 : _Permission$page$name.pages[page.ref];
    if (!userPermissions) return false;
    var check = userPermissions.some(function (obj) {
      return obj.department === user.department && (obj.roles.includes(user.role) || obj.roles === "*");
    });
    return check;
  } else {
    return false;
  }
};
export var hasActionAccess = function hasActionAccess(department, action) {
  var _Permission$departmen;
  var user = getDecodedToken();
  if (!user || !action) return false;
  if (!Permission[department]) return false;
  var actionPermissions = (_Permission$departmen = Permission[department]) === null || _Permission$departmen === void 0 ? void 0 : _Permission$departmen.actions[action];
  if (!actionPermissions) return false;
  var check = actionPermissions.some(function (obj) {
    return obj.department === user.department && (obj.roles.includes(user.role) || obj.roles === "*");
  });
  return check;
};