import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import jQuery from "../../_snowpack/pkg/jquery.js";
// import Barba from "@barba/core";
import Swup from "../../_snowpack/pkg/swup.js";
import SwupProgressPlugin from "../../_snowpack/pkg/@swup/progress-plugin.js";
import SwupPreloadPlugin from "../../_snowpack/pkg/@swup/preload-plugin.js";
import SwupDebugPlugin from "../../_snowpack/pkg/@swup/debug-plugin.js";
// import "https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.13/js/select2.min.js";
import "./plugins/select2.css";
import Preloader from "./components/preloader/Preloader.js";
import Sidemenu from "./components/Sidemenu/Sidemenu.js";
import Header from "./components/Header/Header.js";
import Main from "../../controller/Main.js";
import axios from "../../_snowpack/pkg/axios.js";
import "./components/table/table.css";
import "./components/table/Pagination.css";
import "./components/Toolbar/Toolbar.css";
import "./components/Alert/Alert.css";
import "./components/Button/Button.css";
import "./components/Shimmer/Shimmer.css";
import "./components/Sidebar/Sidebar.css";
import "./components/Toaster/Toaster.css";
import "./components/Header/Header.css";
// import "quill/dist/quill.snow.css";
import "./App.css";
import "./admin-style.css";
import "./plugins/quill.css";
import "./components/preloader/preload.css";
import getDecodedToken from "../../controller/auth/decodeToken.js";
import { getUser } from "./functions/Auth/getUser.js";
import router from "./functions/Auth/router.js";
import getNamespace from "../../controller/helpers/getNamspace.js";
(function () {
  window.onload = function () {
    window.$ = window.jQuery = jQuery;
    window.axios = axios;
    import("https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.13/js/select2.min.js");

    // app basics
    window.swup = new Swup({
      plugins: [new SwupProgressPlugin()
      // new SwupPreloadPlugin({ preloadVisibleLinks: true }),
      // new SwupDebugPlugin(),
      ]
    });
    window.appHeader = new Header();
    window.appHeader.show();
    var loader = new Preloader();
    window.app_loader = loader;
    window.sidemenu = new Sidemenu();
    window.sidemenu.init({
      prevent: "login"
    });
    window.tinyMceLoaded = false;

    /* enable dark mode */

    // if (getNamespace().namespace == "login") {
    //   if (getUser()) {
    //     router(getUser().department);
    //   }
    // }

    Main();
    (function () {
      $("#login_form").submit(function (e) {
        e.preventDefault();
        var fd = new FormData();
        fd.append("username", $("#username").val());
        fd.append("password", $("#password").val());

        // login action
        axios.post(__SNOWPACK_ENV__.API_URL + "/action/loginAction.php", fd).then(function (response) {
          if (response.data.success) {
            // Store the token in localStorage or sessionStorage
            localStorage.setItem("token", response.data.token);
            // let path = ''
            var user = getDecodedToken();
            router(user.department);
          } else {
            alert(response.data.msg);
          }
        });
      });
    })();

    // $("body").fadeIn(1000);
    // show frame
  };
})();