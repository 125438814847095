import { e as e$1 } from '../common/index.module-fa5290f4.js';

function s() {
  return s = Object.assign ? Object.assign.bind() : function (t) {
    for (var s = 1; s < arguments.length; s++) {
      var e = arguments[s];
      for (var i in e) Object.prototype.hasOwnProperty.call(e, i) && (t[i] = e[i]);
    }
    return t;
  }, s.apply(this, arguments);
}
class e {
  constructor({
    className: t,
    styleAttr: s,
    animationDuration: e,
    minValue: i,
    initialValue: r,
    trickleValue: a
  } = {}) {
    this.value = 0, this.visible = !1, this.hiding = !1, this.className = "progress-bar", this.styleAttr = "data-progressbar-styles data-swup-theme", this.animationDuration = 300, this.minValue = .1, this.initialValue = .25, this.trickleValue = .03, this.trickleInterval = void 0, this.styleElement = void 0, this.progressElement = void 0, this.trickle = () => {
      const t = Math.random() * this.trickleValue;
      this.setValue(this.value + t);
    }, void 0 !== t && (this.className = String(t)), void 0 !== s && (this.styleAttr = String(s)), void 0 !== e && (this.animationDuration = Number(e)), void 0 !== i && (this.minValue = Number(i)), void 0 !== r && (this.initialValue = Number(r)), void 0 !== a && (this.trickleValue = Number(a)), this.styleElement = this.createStyleElement(), this.progressElement = this.createProgressElement();
  }
  get defaultStyles() {
    return `\n\t\t.${this.className} {\n\t\t\tposition: fixed;\n\t\t\tdisplay: block;\n\t\t\ttop: 0;\n\t\t\tleft: 0;\n      width: 100%;\n\t\t\theight: 3px;\n\t\t\tbackground-color: black;\n\t\t\tz-index: 9999;\n\t\t\ttransition:\n\t\t\t\ttransform ${this.animationDuration}ms ease-out,\n\t\t\t\topacity ${this.animationDuration / 2}ms ${this.animationDuration / 2}ms ease-in;\n\t  transform: translate3d(0, 0, 0) scaleX(var(--progress, 0));\n      transform-origin: 0;\n\t\t}\n\t`;
  }
  show() {
    this.visible || (this.visible = !0, this.installStyleElement(), this.installProgressElement(), this.startTrickling());
  }
  hide() {
    this.visible && !this.hiding && (this.hiding = !0, this.fadeProgressElement(() => {
      this.uninstallProgressElement(), this.stopTrickling(), this.visible = !1, this.hiding = !1;
    }));
  }
  setValue(t) {
    this.value = Math.min(1, Math.max(this.minValue, t)), this.refresh();
  }
  installStyleElement() {
    document.head.prepend(this.styleElement);
  }
  installProgressElement() {
    this.progressElement.style.setProperty("--progress", String(0)), this.progressElement.style.opacity = "1", document.body.prepend(this.progressElement), this.progressElement.scrollTop = 0, this.setValue(Math.random() * this.initialValue);
  }
  fadeProgressElement(t) {
    this.progressElement.style.opacity = "0", setTimeout(t, 1.5 * this.animationDuration);
  }
  uninstallProgressElement() {
    this.progressElement.remove();
  }
  startTrickling() {
    this.trickleInterval || (this.trickleInterval = window.setInterval(this.trickle, this.animationDuration));
  }
  stopTrickling() {
    window.clearInterval(this.trickleInterval), delete this.trickleInterval;
  }
  refresh() {
    requestAnimationFrame(() => {
      this.progressElement.style.setProperty("--progress", String(this.value));
    });
  }
  createStyleElement() {
    const t = document.createElement("style");
    return this.styleAttr.split(" ").forEach(s => t.setAttribute(s, "")), t.textContent = this.defaultStyles, t;
  }
  createProgressElement() {
    const t = document.createElement("div");
    return t.className = this.className, t.setAttribute("aria-hidden", "true"), t;
  }
}
class i extends e$1 {
  constructor(t = {}) {
    super(), this.name = "SwupProgressPlugin", this.defaults = {
      className: "swup-progress-bar",
      delay: 300,
      transition: 300,
      minValue: .1,
      initialValue: .25,
      finishAnimation: !0
    }, this.options = void 0, this.progressBar = void 0, this.showProgressBarTimeout = void 0, this.hideProgressBarTimeout = void 0, this.options = s({}, this.defaults, t);
    const {
      className: i,
      minValue: r,
      initialValue: a,
      transition: n
    } = this.options;
    this.progressBar = new e({
      className: i,
      minValue: r,
      initialValue: a,
      animationDuration: n
    });
  }
  mount() {
    this.on("visit:start", this.startShowingProgress), this.on("page:view", this.stopShowingProgress);
  }
  startShowingProgress() {
    this.progressBar.setValue(0), this.showProgressBarAfterDelay();
  }
  stopShowingProgress() {
    this.progressBar.setValue(1), this.options.finishAnimation ? this.finishAnimationAndHideProgressBar() : this.hideProgressBar();
  }
  showProgressBar() {
    this.cancelHideProgressBarTimeout(), this.progressBar.show();
  }
  showProgressBarAfterDelay() {
    this.cancelShowProgressBarTimeout(), this.cancelHideProgressBarTimeout(), this.showProgressBarTimeout = window.setTimeout(this.showProgressBar.bind(this), this.options.delay);
  }
  hideProgressBar() {
    this.cancelShowProgressBarTimeout(), this.progressBar.hide();
  }
  finishAnimationAndHideProgressBar() {
    this.cancelShowProgressBarTimeout(), this.hideProgressBarTimeout = window.setTimeout(this.hideProgressBar.bind(this), this.options.transition);
  }
  cancelShowProgressBarTimeout() {
    window.clearTimeout(this.showProgressBarTimeout), delete this.showProgressBarTimeout;
  }
  cancelHideProgressBarTimeout() {
    window.clearTimeout(this.hideProgressBarTimeout), delete this.hideProgressBarTimeout;
  }
}

export default i;
