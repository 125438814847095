function presets() {
  // for disable mouse scroll on inputs
  // $(document).on("wheel", "input[type=number]", function (e) {
  //   $(this).blur();
  // });
  // $("label").click(function () {
  //   $(this).parent().find('input[type="checkbox"]').trigger("click");
  // });
  $(".shimmer").hide();
  $(".sidebar").remove();
  // let sidebar = new Sidebar();
  // sidebar.instances = {};
}
export default presets;