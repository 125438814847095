// import Shimmer from "../public/src/components/Shimmer/Shimmer";
import getNamespace from "./helpers/getNamspace.js";
import Preloader from "../public/src/components/preloader/Preloader.js";
import presets from "./helpers/presets.js";
import { hasPageAccess } from "./auth/hasAccess.js";
import { getParametersFromURL } from "../public/src/functions/common/common.js";
import Notification from "../public/src/components/Notification/Notification.js";
var Main = function Main() {
  // let shimmer = new Shimmer();
  var loader = new Preloader();
  loader.render($(".frame"), {
    position: "fixed",
    width: "calc(100% - 265px)",
    height: "calc(100% - 60px)",
    right: 0,
    bottom: 0
  });
  var namespaceManager = function namespaceManager() {
    // hasPageAccess();
    if (!hasPageAccess()) {
      if (getNamespace().namespace != "login") {
        window.location = "http://localhost:8080/";
      } else {}
    }
    var parentDiv = document.getElementById("swup");
    var currentNamespace = parentDiv.getAttribute("data-barba-namespace");
    $(".frame").fadeIn(1000);

    // enable notification

    switch (currentNamespace) {
      case "admin":
        import("./Admin.js").then(function (x) {
          x.Admin(loader);
        });
        break;
      case "contract":
        import("./Contract.js").then(function (x) {
          x.Contract(loader);
        });
        break;
      case "sales":
        import("./Sales.js").then(function (x) {
          x.Sales();
        });
        break;
      case "quotation":
        import("./Quotation.js").then(function (x) {
          x.Quotation(loader);
        });
        break;
      case "Accounts":
        import("./Accounts.js").then(function (x) {
          x.Accounts(loader);
        });
        break;
      case "Visa":
        import("./Visa.js").then(function (x) {
          x.Visa(loader);
        });
        break;
      case "Operation":
        import("./Operation.js").then(function (x) {
          x.Operation(loader);
        });
        break;
      case "Profile":
        import("./Profile.js").then(function (x) {
          x.Profile(loader);
        });
        break;
    }
  };
  swup.hooks.on("content:replace", function () {}, {
    before: true
  });
  swup.hooks.on("page:view", function (event) {
    namespaceManager();
    presets();
  });
  /* call by default */
  namespaceManager();
};
export default Main;