import * as __SNOWPACK_ENV__ from '../../../../_snowpack/env.js';

function router(department) {
  switch (department) {
    case "contract":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/contract/nationality.html";
      break;
    case "sales":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/sales/";
      break;
    case "quotation":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/quotation/generateQuotation.html";
      break;
    case "visa":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/visa/generateVisa.html";
      break;
    case "admin":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/admin/escalations.html";
      break;
    case "accounts":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/accounts/ACR.html";
      break;
    case "operations":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/operation/job-movent-chart.html";
      break;
    case "file_handler":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/quotation/listQuotation.html";
      break;
    case "client_service":
      window.location = __SNOWPACK_ENV__.MENU_URL + "/view/operation/job-movent-chart.html";
      break;
  }
}
export default router;